import { cond, identity } from 'lodash/fp';
import { filenameToUrl } from './filenameToUrl';
import { urlToStorageReference } from './urlToStorageReference';

export const filenameToRef = async filename =>
(
  window
  .Promise
  .resolve(filename)
  .then(filenameToUrl)
  .then(cond([[identity, urlToStorageReference]]))
);