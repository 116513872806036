/* eslint-disable no-unused-vars */
/* eslint-disable no-unexpected-multiline */

import { get, pipe } from 'lodash/fp'

import {
  app,
} from '@thomasphan/vue-components/src/functions/initializeApp'

import {
  getUid,
} from '@thomasphan/vue-components/src/functions/getUid'

import {
  collection,
  getDocs as _getDocs,
  getFirestore,
} from 'firebase/firestore'

const firestore = getFirestore(app)

export const getDocs = async function ()
{
  const uid = await getUid()

  return _getDocs(collection(firestore, `users/${ uid }/items`))
  .then(pipe([
    get('docs'),
  ]))
}