<template lang='pug'>
div
  v-container
    v-row
      v-col(
        cols='1'
        sm='1'
        md='4'
        lg='3'
        xl='4'
      )
      v-col(
        cols='10'
        sm='10'
        md='4'
        lg='6'
        xl='4'
      )
        v-file-input(
          accept='image/*'
          @change='fileParse'
          prepend-icon='mdi-image'
          label='Upload'
        )
      v-col(
        cols='1'
        sm='1'
        md='4'
        lg='3'
        xl='4'
      )
    v-row
      v-col(
        v-for='doc in documentObjects'
        :key='doc.downloadUrl'
        cols='6'
        sm='4'
        md='4'
        lg='3'
        xl='2'
      )
        v-card(
          :disabled='doc.deleted'
        )
          v-img(
            :src='doc.downloadUrl'
          )
          v-card-actions
            v-spacer
            v-btn(
              icon
              :href='doc.downloadUrl'
              target='_blank'
            )
              v-icon mdi-magnify
            v-btn(
              v-if='doc.href'
              icon
              :href='doc.href'
            )
              v-icon mdi-link
            v-btn(
              icon
              @click='deleteDocumentWithDocumentObject(doc)'
            )
              v-icon mdi-delete
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-unexpected-multiline */

import {
  app,
} from '@thomasphan/vue-components/src/functions/initializeApp'

import {
  getUid,
} from '@thomasphan/vue-components/src/functions/getUid'

import {
  addDoc,
  collection,
  getFirestore,
  deleteDoc,
} from 'firebase/firestore'

import { fileToDownloadUrl } from '../functions/fileToDownloadUrl'
import { getDocs } from '../functions/getDocs'
import { fromPairs, get, includes, invoke, map, pipe, placeholder, set, tap } from 'lodash/fp'

const db = getFirestore(app)

const fileParse = async function (file)
{
  const uid = await getUid()

  await window
  .Promise
  .resolve(file)
  .then(fileToDownloadUrl)
  .then(downloadUrl => addDoc(collection(db, `users/${ uid }/items`), { downloadUrl }))

  this.docs = await getDocs()
}

const mounted = async function ()
{
  this.docs = await getDocs()
}

const documentObjects = function ()
{
  return pipe
  ([
    get('docs'),
    map(invoke('data')),
    map
    (
      documentObject =>
      (
        pipe
        ([
          get('downloadUrl'),
          includes(placeholder, this.documentObjectsDeleted),
          set('deleted')(placeholder, documentObject),
        ])
        (documentObject)
      )
    ),
  ])
  (this)
}

const referenceByDownloadUrl = function ()
{
  return pipe
  ([
    get('docs'),
    map(document => [document.data().downloadUrl, document.ref]),
    fromPairs,
  ])
  (this)
}

const deleteDocumentWithDocumentObject = async function (documentObject)
{
  const reference = pipe
  ([
    get('downloadUrl'),
    get(placeholder, this.referenceByDownloadUrl),
  ])
  (documentObject)

  await deleteDoc(reference)

  const downloadUrl = pipe
  ([
    get('downloadUrl'),
  ])
  (documentObject)

  this.documentObjectsDeleted.push(downloadUrl)
}

export default
{
  computed:
  {
    documentObjects,
    referenceByDownloadUrl,
  },
  data()
  {
    return {
      docs: null,
      documentObjectsDeleted: [],
    }
  },
  methods:
  {
    deleteDocumentWithDocumentObject,
    fileParse,
  },
  mounted,
  name: 'Home',
}
</script>