import { app } from '@thomasphan/vue-components/src/functions/initializeApp';

import {
  getStorage,
  ref,
} from 'firebase/storage';

const storage = getStorage(app);

export const urlToStorageReference = url =>
(
  ref(storage, url)
);