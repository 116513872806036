<template lang='pug'>
v-app
  v-app-bar(
    app
    dark
    flat
  )
    v-btn.mr-2(
      to='/'
      text
    )
      | StyleGrabber
    v-btn.mr-2(
      href='https://chrome.google.com/webstore/detail/stylegrabber-grabber/cenljjicagmhplijnbgmecgflbempkae'
      text
    )
      | Grabber
    v-spacer
    TheUserIcon
  v-main
    Uid
      router-view
</template>

<script>
import TheUserIcon from '@thomasphan/vue-components/src/components/TheUserIcon.vue';
import Uid from '@thomasphan/vue-components/src/components/Uid.vue';

export default
{
  name: 'App',
  components:
  {
    TheUserIcon,
    Uid,
  },
};
</script>