/* eslint-disable no-unused-vars */
/* eslint-disable no-unexpected-multiline */

import { cond, get, identity, stubTrue } from 'lodash/fp'

import {
  getDownloadURL,
  uploadBytes,
} from 'firebase/storage'

import { filenameToRef } from './filenameToRef'

export const fileToDownloadUrl = function (file)
{
  // const buffer = await file.arrayBuffer()

  // const view = new window.DataView(buffer)

  return window
  .Promise
  .resolve(file)
  .then(get('name'))
  .then(filenameToRef)
  .then(cond([
    [identity, identity],
    [stubTrue, () => window.Promise.reject('filenameToRef')],
  ]))
  .then(ref => uploadBytes(ref, file))
  .then(get('ref'))
  .then(getDownloadURL)
}