import { invokeArgs, nth, pipe, toLower } from 'lodash/fp';

export const filenameToExtension = filename =>
(
  pipe
  ([
    invokeArgs('match')([/\.([^.]+)$/]),
    nth(1),
    toLower,
  ])
  (filename)
);