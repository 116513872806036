import { cond, identity, pipe, stubTrue } from 'lodash/fp';
import { getUid } from '@thomasphan/vue-components/src/functions/getUid';

import { filenameToExtension } from './filenameToExtension';

export const filenameToUrl = async filename =>
{
  const uid = await getUid();

  return pipe
  ([
    filenameToExtension,
    cond
    ([
      [identity, extension => `images/${ uid }/${ window.Date.now() }.${ extension }`],
      [stubTrue, () => `images/${ uid }/${ window.Date.now() }`],
    ]),
  ])
  (filename);
};